import { useState, useContext, useCallback, useEffect, useRef } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import Banner from "./banner.png";
import Mining from "./Gundabadu.mp3";
import {ReactComponent as Opensea} from "./opensea.svg";
import {ReactComponent as Twitter} from "./twitter.svg";

function App() {
  const [count, setCount] = useState(1);
  const audioRef = useRef(null);
  const [mintedCount, setMintedCount] = useState(0);
  const { connectBrowserWallet, mint, userData, transactionHash, contractDetails } =
    useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const connectWallet = () => {
    audioRef.current.play();
    audioRef.current.volume = 0.3;
    connectBrowserWallet();
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if(!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)))
    }
  }, [contractDetails])

  return (
    <>
     <audio ref={audioRef} playsInline autoPlay loop>
        <source src={Mining} type="audio/mpeg" />
      </audio>
      <div className="greed-container">
        <div className="greed-links">
            <a
            href="https://opensea.io/collection/greedy-town-official"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item"
          >
            <Opensea />
          </a>
          {/* <a href="https://twitter.com/_GreedyGoblins" target="_blank" rel="noreferrer" className="greed-link-item greed-twitter">
           
          </a> */}
          <div className='coming-soon'>
             <Twitter/>
            Coming Soon
          </div>
        </div>
        <img src={Banner} alt="Greedy Goblin" />
        <div className="greed-form">
          <h1 className="f-shadow">GreEedyy Town</h1>
          {/* <h2>MINT 0.0033</h2> */}
         {!!contractDetails && (
            <p className="counter">
              {mintedCount} / 3333
            </p>
          )}
          {!!userData ? (
            <>
              <div className="greed-form-counter">
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="minus"
                >
                  -
                </button>
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="plus"
                >
                  +
                </button>
                <button
                  onClick={handleCustomMint}
                  type="button"
                  className="claim-btn"
                >
                  Mint
                </button>
              </div>
              <div className="greed-claimers">
                <button
                  onClick={() => mint(3)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 3
                </button>
                <button
                  onClick={() => mint(5)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 5
                </button>
                <button
                  onClick={() => mint(10)}
                  type="button"
                  className="claim-btn"
                >
                  Mint 10
                </button>
              </div>
              {transactionHash && (
                <a
                  href={`https://etherscan.io/tx/${transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="claim-btn"
                >
                  See transaction
                </a>
              )}
            </>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          )}

          <p>bee greedYy noOo greedyy prriiiccee 0.0033 </p>
          <p>369 in towwnn reserved for greedyys</p>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
